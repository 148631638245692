import "./App.css";
import { useState } from "react";
import Task from "./components/Task";

function App() {
  const [newTask, setNewTask] = useState("");
  const [taskList, setTaskList] = useState([]);
  const handleInput = (event) => setNewTask(event.target.value);
  const addTask = () => {
    const task = {
      id: taskList.length === 0 ? 1 : taskList[taskList.length - 1].id + 1,
      taskName: newTask,
      completed: false,
    };
    setTaskList([...taskList, task]);
  };
  const completeTask = (taskId) => {
    console.log("Completed");
    const newTaskList = taskList.map((task) => {
      return task.id === taskId ? { ...task, completed: true } : task;
    });
    setTaskList(newTaskList);
  };
  const deleteTask = (taskId) => {
    const newTaskList = taskList.filter((task) => task.id !== taskId);
    setTaskList(newTaskList);
  };
  return (
    <div className="App">
      <h1>Todo list</h1>
      <div className="addTask">
        <input type="text" placeholder="Add new task" onChange={handleInput} />
        <button onClick={addTask}>Add task</button>
      </div>
      <div className="todoList">
        {taskList.length === 0 ? (
          <h2>You have no tasks</h2>
        ) : (
          <h2>Your tasks:</h2>
        )}
        {taskList.map((task) => {
          return (
            <Task
              key={task.id.toString()}
              id={task.id}
              name={task.taskName}
              deleteTask={deleteTask}
              completeTask={completeTask}
              completed={task.completed}
            />
          );
        })}
      </div>
    </div>
  );
}

export default App;
