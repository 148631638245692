const Task = ({ id, name, completed, completeTask, deleteTask }) => {
  return (
    <div style={{ backgroundColor: completed === true ? "green" : "white" }}>
      <p>
        {id}. {name}
      </p>
      <button onClick={() => completeTask(id)}>Done</button>
      <button onClick={() => deleteTask(id)}>Delete</button>
    </div>
  );
};

export default Task;
